import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import indexRoutes from "./routes/main";
import { history } from "./lib/History";
import NavbarTop from "./components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/theme.css";
import "../src/assets/css/stylesButton.css";
import Footer from "./components/Footer";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";
import i18next from "i18next";
import { Container } from "react-bootstrap";
import common_en from "./static/trans/en.json";
import common_gr from "./static/trans/gr.json";
import { render } from "react-dom";

const resources = {
  en: {
    common: common_en,
  },
  gr: {
    common: common_gr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: ["gr", "en"],
  lng: sessionStorage.getItem("language") || "gr",
});

window.__dev__ = process.env.NODE_ENV !== "production";

const root = document.getElementById("root");
render(
  <I18nextProvider i18n={i18next}>
    <Router history={history}>
      <>
        <NavbarTop />
        <Container className="container-social-icons" fluid></Container>
        <Switch>
          {indexRoutes.map((prop, key) => {
            if (prop.redirect)
              return <Redirect key={key} from={prop.from} to={prop.to} />;
            return (
              <Route path={prop.path} key={key} component={prop.component} />
            );
          })}
        </Switch>
        <Footer />
      </>
    </Router>
  </I18nextProvider>,
  root
);
