import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import FirstImage from "../assets/img/carouselImages/MyPostlog.png";
import SecImage from "../assets/img/carouselImages/ReCo_LOGO_Gr_Blue.jpg";
import ThirdImage from "../assets/img/carouselImages/banner-horizontal-300ppi.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("common");

  return (
    <div className="pt-5">
      <footer>
        <Container style={{ maxWidth: "85%" }} className="pt-3">
          <hr style={{ borderTop: "6px double #8c8b8b" }} />
          <div className="row">
            <div className="col-8 col-md-2 mb-3 pt-4">
              <h5>{t("home")}</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link
                    to="/general-info"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("general")}
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/courses"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("courses")}
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/carriers"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("carriers")}
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/application-form"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("application")}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-8 col-md-2 mb-3 pt-4">
              <h5>{t("pages")}</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link
                    to="/courses"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("courses")}
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/faculty"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("faculty")}
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/equipment"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("equipment")}
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/contact"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("contactTitle")}
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/faq"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("faq")}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-8 col-md-2 mb-3 pt-4">
              <h5>{t("documents")}</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link
                    to="/application-form"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("application")}
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link
                    to="/application-form"
                    className="nav-link p-0 text-muted color-black"
                  >
                    {t("announcementMsc")}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-5 offset-md-1 mb-3">
              <Container style={{ paddingTop: "50px" }}>
                <Row style={{ height: "100px" }}>
                  <Col xs={4} xl={4}>
                    <div className="box">
                      <div className="imgwrap">
                        <img src={FirstImage} alt="APTH" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} xl={4}>
                    <div className="box">
                      <div className="imgwrap">
                        <img src={SecImage} alt="APTH" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} xl={4}>
                    <div className="box">
                      <div className="imgwrap">
                        <img src={ThirdImage} alt="APTH" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
            <p>
              © 2024 Michalis Tsiourdas
              <br /> Designed by Katerina Eleftheriadou Inc. All rights
              reserved.
            </p>

            <div className="sharethis-inline-share-buttons"></div>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
