import React, { useTransition } from "react";
import Image1 from "../assets/img/testimonials/Picture1.jpg";
import Image2 from "../assets/img/testimonials/Picture2.jpg";
import Image3 from "../assets/img/testimonials/Picture3.jpg";
import Image4 from "../assets/img/testimonials/Picture4.jpg";
import Image5 from "../assets/img/testimonials/Picture5.jpg";
import Image6 from "../assets/img/testimonials/Picture6.jpg";
import Image7 from "../assets/img/testimonials/Picture7.jpg";
import Image8 from "../assets/img/testimonials/Picture8.jpg";
import Image9 from "../assets/img/testimonials/Picture9.jpg";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Testimonials = (props) => {
  const { t } = useTranslation("common");
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h3>{t("ourStudentsSay")}</h3>
          <div
            id="myCarousel"
            className="carousel slide"
            data-ride="carousel"
            pause="hover"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#myCarousel"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#myCarousel" data-slide-to="1"></li>
              <li data-target="#myCarousel" data-slide-to="2"></li>
              <li data-target="#myCarousel" data-slide-to="3"></li>
              <li data-target="#myCarousel" data-slide-to="4"></li>
              <li data-target="#myCarousel" data-slide-to="5"></li>
              <li data-target="#myCarousel" data-slide-to="6"></li>
              <li data-target="#myCarousel" data-slide-to="7"></li>
              <li data-target="#myCarousel" data-slide-to="8"></li>
            </ol>

            <div className="carousel-inner">
              <div className="carousel-item active">
                <Row>
                  <Col sm={12}>
                    <div className="media">
                      <img src={Image1} className="mr-3" alt="" />
                      <div className="media-body">
                        <div className="testimonial">
                          <p className="testimonial-text">
                            {t("firstTestimonialText")}
                          </p>
                          <p className="overview">
                            <b>{t("firstName")}</b>
                            {t("firstJobTitle")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="carousel-item">
                <Row>
                  <Col sm={12}>
                    <div className="media">
                      <img src={Image2} className="mr-3" alt="" />
                      <div className="media-body">
                        <div className="testimonial">
                          <p className="testimonial-text">
                            {t("secondTestimonialFirstPart")}
                            <br />
                            {t("secondTestimonialSecondPart")}
                          </p>
                          <p class="overview">
                            <b>{t("secondName")}</b>
                            {t("secondJobTitle")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="carousel-item">
                <Row>
                  <Col sm={12}>
                    <div className="media">
                      <img src={Image4} className="mr-3" alt="" />
                      <div className="media-body">
                        <div className="testimonial">
                          <p className="testimonial-text">
                            {t("thirdTestimonialFirstPart")}
                            <br /> {t("thirdTestimonialSecondPart")}
                          </p>
                          <p className="overview">
                            <b>{t("thirdName")}</b>
                            {t("thirdJobTitle")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="carousel-item">
                <Row>
                  <Col sm={12}>
                    <div className="media">
                      <img src={Image3} className="mr-3" alt="" />
                      <div className="media-body">
                        <div className="testimonial">
                          <p className="testimonial-text">
                            {t("fourthTestimonial")}
                          </p>
                          <p className="overview">
                            <b>{t("fourthName")}</b>
                            {t("fourthJobTitle")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="carousel-item">
                <Row>
                  <Col>
                    <div className="media">
                      <img src={Image5} className="mr-3" alt="" />
                      <div className="media-body">
                        <div className="testimonial">
                          <p className="testimonial-text">
                            {t("fifthTestimonial")}
                          </p>
                          <p className="overview">
                            <b>{t("fifthName")}</b>
                            {t("fifthJobTitle")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="carousel-item">
                <Row>
                  <Col>
                    <div className="media">
                      <img src={Image6} className="mr-3" alt="" />
                      <div className="media-body">
                        <div className="testimonial">
                          <p className="testimonial-text">
                            {t("sixthTestimonialPartOne")}
                            <br /> {t("sixthTestimonialPartTwo")}
                            <br />
                            {t("sixthTestimonialPartThree")}
                            <br />
                            {t("sixthTestimonialPartFour")}
                          </p>
                          <p className="overview">
                            <b>{t("sixthName")}</b>
                            {t("sixthJobTitle")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="carousel-item">
                <Row>
                  <Col>
                    <div className="media">
                      <img src={Image7} className="mr-3" alt="" />
                      <div className="media-body">
                        <div className="testimonial">
                          <p className="testimonial-text">
                            {t("sevenTestimonialPartOne")}
                            <br />
                            <br />
                            {t("sevenTestimonialPartTwo")}
                          </p>
                          <p className="overview">
                            <b>{t("seventhName")}</b>
                            {t("seventhJobTitle")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="carousel-item">
                <Row>
                  <Col>
                    <div className="media">
                      <img src={Image8} className="mr-3" alt="" />
                      <div className="media-body">
                        <div className="testimonial">
                          <p className="testimonial-text">
                            {t("eigthTestimonial")}
                          </p>
                          <p className="overview">
                            <b>{t("eigthName")}</b>
                            {t("eightJobTitle")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="carousel-item">
                <Row>
                  <Col>
                    <div className="media">
                      <img src={Image9} className="mr-3" alt="" />
                      <div className="media-body">
                        <div className="testimonial">
                          <p className="testimonial-text">
                            {t("nineTestimonial")}
                          </p>
                          <p className="overview">
                            <b>{t("nineName")}</b>
                            {t("nineJobTitle")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Testimonials;
