import React, { useEffect, useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import photos from "./photos";

function Equipment() {
  const { t } = useTranslation("common");
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container className="pt-5">
        <h1>{t("equipment")}</h1>
        <p style={{ color: "gray" }}>{t("equipmentSubtitle")}</p>
      </Container>
      <Container className=" pt-2">
        <Row>
          <Gallery
            photos={photos}
            direction={"column"}
            onClick={openLightbox}
          />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </Row>
      </Container>
    </>
  );
}

export default Equipment;
