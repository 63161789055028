import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const FacultyDetails = ({ data, id }) => {
  const { t } = useTranslation("common");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Col key={id} xl={4} md={6} className="pt-5">
        <div>
          <Row>
            <Col xl={3} sm={4} xs={4}>
              <img
                alt={data.name}
                src={data.image}
                style={{
                  width: "100px ",
                  height: "106px ",
                }}
              />
            </Col>
            <Col xl={9} sm={8} xs={8}>
              <div>
                <h5>{data.name}</h5>
                <p>{data.title}</p>
                {data.modal ? (
                  <Button variant="outline-info" onClick={handleShow}>
                    {t("info")}
                  </Button>
                ) : (
                  <Button
                    variant="outline-info"
                    onClick={handleShow}
                    href={data.info}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("info")}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      {data.info?.name && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{data.info?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{data.info.info}</Modal.Body>
          <hr />
          {data.info.personalLinks
            ? data.info.personalLinks.map((t) => (
                <Modal.Body>
                  <Button variant="link" href={t} target="_blank">
                    {t}
                  </Button>
                </Modal.Body>
              ))
            : null}
        </Modal>
      )}
    </>
  );
};

export default FacultyDetails;
