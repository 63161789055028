import React, { useEffect } from "react";
import { Container, Col, Row, Card, Button } from "react-bootstrap";
import Header from "../../components/Header";
import { BsDownload } from "react-icons/bs";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";

const Application = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation("common");
  return (
    <div>
      <Header color="orange" title={t("applicationFormTitle")} />
      <Container>
        <Row className="justify-content-center">
          <Col xl={6} className="text-center pt-5">
            <Card
              style={{ margin: "0 auto", height: "600px" }}
              className="text-center"
            >
              <Card.Header>
                <h2>{t("prokuriksi")}</h2>
              </Card.Header>
              <Card.Body>
                <Card.Title></Card.Title>
                <Card.Text className="pt-3">
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <h3>{t("tileOneInfoOne")}</h3>
                    </li>
                    <li>
                      <h3>{t("tileOneInfoTwo")}</h3>
                    </li>
                    <li>
                      <h3>{t("tileOneInfoThree")}</h3>
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button
                  href="/info/ΠΜΣ_ΔΑΕ_προκήρυξη_2024-2025.pdf"
                  type="button"
                  variant="outlined"
                  className="orange"
                  size="lg"
                  target="_blank"
                  rel="noreferrer"
                  download="ΠΜΣ_ΔΑΕ_προκήρυξη_2024-2025.pdf"
                >
                  <BsDownload className="orange" />
                  {t("downloadProkuriksi")}
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col xl={6} className="text-center pt-5">
            <Card style={{ margin: "0 auto", height: "600px" }}>
              <Card.Header>
                <h2>{t("form")}</h2>
              </Card.Header>
              <Card.Body>
                <Card.Title></Card.Title>
                <Card.Text className="pt-3">
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <h3>{t("tileTwoInfoOne")}</h3>
                    </li>
                    <li>
                      <h3>{t("tileTwoInfoTwo")}</h3>
                    </li>
                    <li>
                      <h3>{t("tileTwoInfoThree")}</h3>
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button
                  href="/info/aitisi_msc_dae.docx"
                  type="button"
                  variant="outlined"
                  className="orange"
                  size="lg"
                  target="_blank"
                  rel="noreferrer"
                  download="aitisi_msc_dae.docx"
                >
                  <BsDownload className="orange" />
                  {t("downloadForm")}
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Row className="pt-5">
          <Col>
            <h3>
              {t("plainText")}{" "}
              <a
                href="https://oauth2.it.auth.gr/auth/realms/universis/protocol/openid-connect/auth?redirect_uri=https%3A%2F%2Fenrollment.auth.gr%2Fauth%2Fcallback%2Findex.html&response_type=code&client_id=register&code_challenge=dmmICpnWpECgF3zi8pECn9GOKr52kNyrdAeGmWKeMJA&code_challenge_method=S256&scope=candidates&state=53616c7465645f5f36191b212bb55ebb4b91d206129be3c6debcb68ebe909271"
                target="__blank"
              >
                enrollment.auth.gr
              </a>
              {t("plainText2")}
              <a
                href="/info/Αυτοεγγραφή_φοιτητών.pdf"
                type="button"
                target="_blank"
                rel="noreferrer"
                download="Αυτοεγγραφή_φοιτητών.pdf"
              >
                {t("here")}
              </a>
            </h3>
          </Col>
        </Row>
      </Container>
      <>
        <Header color="blue" title={t("oath")} />
        <div className="container pt-3">{t("oathText")}</div>
        <div className="container pt-5">
          <Accordion className="faq-accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("oathFormTitle")}</Accordion.Header>
              <Accordion.Body className="accordion-body-color">
                <ol>
                  <li>
                    <b>{t("oathFormText1")}</b>
                    {t("oathFormText11")}
                  </li>
                  <li>
                    <b>{t("oathFormText2")}</b>
                    {t("oathFormText3")}
                  </li>
                  <li>
                    <b>{t("oathFormText4")}</b>
                    {t("oathFormText5")}
                  </li>
                  <li>
                    <b>{t("oathFormText6")}</b>
                    {t("oathFormText7")}
                  </li>
                </ol>
                <div className="pt-3">
                  {t("oathFormText8")}
                  <b>{t("oathFormText9")}</b>
                  {t("oathFormText10")}&nbsp;
                  <a
                    href="https://it.auth.gr/manuals/aitisi-orkomosias"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://it.auth.gr/manuals/aitisi-orkomosias
                  </a>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="faq-accordion">
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t("memvraniTitle")}</Accordion.Header>
              <Accordion.Body className="accordion-body-color">
                {t("memvraniText")}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </>
    </div>
  );
};

export default Application;
