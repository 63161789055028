import React, { useEffect } from "react";
import Header from "../../components/Header";
import { Row, Container, Col, Tabs, Tab } from "react-bootstrap";
import IconData from "../../assets/img/data-analysis-blue-icon.svg";
import imageCourses from "../../assets/img/NEW-TIMELINE-10.png";
import imageMobileStudies from "../../assets/img/NEW-TIMELINE-11.png";
import Accordion from "../../components/Accordion";
import { useTranslation } from "react-i18next";

const Course = () => {
  const { t } = useTranslation("common");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const panels = [
    {
      label: t("courseTitle1"),
      path: 1,
      content: {
        hours: 4,
        ects: 10,
        text: t("course1Text"),
        semester: t("semesterA"),
        books: [t("book1")],
        grades: [
          t("multipleChoice") + ": 20%",
          t("proodousSpss") + ": 20%",
          t("finalExam2") + ": 60%",
        ],
      },
    },
    {
      label: t("courseTitle2"),
      path: 1,
      content: {
        hours: 4,
        ects: 10,
        text: t("course2Text"),
        semester: t("semesterA"),
        books: [t("book2")],
        grades: [
          t("proodous") + " (20%)",
          t("ergasies") + " (40%)",
          t("finalExam") + " (40%)",
        ],
      },
    },
    {
      label: t("courseTitle3"),
      path: 1,
      content: {
        hours: 4,
        ects: 10,
        text: t("course3Text"),
        semester: t("semesterA"),
        books: [t("book3")],
        grades: [
          t("proodous") + " (20%)",
          t("ergasies") + " (30%)",
          t("finalExam") + " (50%)",
        ],
      },
    },
    {
      label: t("courseTitle4"),
      path: 1,
      content: {
        hours: 4,
        ects: 10,
        text: t("course4Text"),
        semester: t("semesterB"),
        books: [t("book4"), t("book5")],
        grades: [
          t("proodous") + " (30%)",
          t("ergasies") + " (20%)",
          t("finalExam") + " (50%)",
        ],
      },
    },
    {
      label: t("courseTitle5"),
      path: 1,
      content: {
        hours: 4,
        ects: 10,
        text: t("course5Text"),
        semester: t("semesterB"),
        books: [t("book6"), t("book7")],
        grades: [
          t("proodous") + " (20%)",
          t("ergasies") + " (30%)",
          t("finalExam") + " (50%)",
        ],
      },
    },
    {
      label: t("courseTitle6"),
      path: 1,
      content: {
        hours: 4,
        ects: 10,
        text: t("course6Text"),
        semester: t("semesterB"),
        books: [t("book4"), t("book8")],
        grades: [
          t("proodous") + " (30%)",
          t("ergasies") + " (30%)",
          t("finalExam") + " (40%)",
        ],
      },
    },
    {
      label: t("courseTitle7"),
      path: 1,
      content: {
        hours: 4,
        ects: 15,
        text: t("course7Text"),
        semester: t("semesterC"),
        books: [],
        grades: [t("proodous") + " (40%)", t("finalExam2") + " (60%)"],
      },
    },
    {
      label: t("courseTitle8"),
      path: 1,
      content: {
        hours: 4,
        ects: 15,
        text: "",
        semester: t("semesterC"),
        books: [],
        grades: [],
      },
    },
    {
      label: t("courseTitle1"),
      path: 2,
      content: {
        hours: 4,
        ects: 10,
        text: t("course1Text"),
        semester: t("semesterA"),
        books: [t("book1")],
        grades: [
          t("multipleChoice") + ": 20%",
          t("proodousSpss") + ": 20%",
          t("finalExam2") + ": 60%",
        ],
      },
    },
    {
      label: t("courseTitle2"),
      path: 2,
      content: {
        hours: 4,
        ects: 10,
        text: t("course2Text"),
        semester: t("semesterA"),
        books: [t("book2")],
        grades: [
          t("proodous") + " (20%)",
          t("ergasies") + " (40%)",
          t("finalExam") + " (40%)",
        ],
      },
    },
    {
      label: t("courseTitle3"),
      path: 2,
      content: {
        hours: 4,
        ects: 10,
        text: t("course3Text"),
        semester: t("semesterA"),
        books: [t("book3")],
        grades: [
          t("proodous") + " (20%)",
          t("ergasies") + " (30%)",
          t("finalExam") + " (50%)",
        ],
      },
    },
    {
      label: t("courseTitle10"),
      path: 2,
      content: {
        hours: 4,
        ects: 10,
        text: t("course10Text"),
        semester: t("semesterB"),
        books: [t("book4"), t("book8")],
        grades: [
          t("proodous") + " (30%)",
          t("ergasies") + " (20%)",
          t("finalExam") + " (50%)",
        ],
      },
    },
    {
      label: t("courseTitle11"),
      path: 2,
      content: {
        hours: 4,
        ects: 10,
        text: t("course11Text"),
        semester: t("semesterB"),
        books: [t("book6"), t("book7")],
        grades: [
          t("proodous") + " (20%)",
          t("ergasies") + " (30%)",
          t("finalExam") + " (50%)",
        ],
      },
    },
    {
      label: t("courseTitle12"),
      path: 2,
      content: {
        hours: 4,
        ects: 10,
        text: t("course12Text"),
        semester: t("semesterB"),
        books: [t("book4"), t("book8")],
        grades: [
          t("proodous") + " (30%)",
          t("ergasies") + " (30%)",
          t("finalExam") + " (40%)",
        ],
      },
    },
    {
      label: t("courseTitle13"),
      path: 2,
      content: {
        hours: 4,
        ects: 15,
        text: t("course13Text"),
        semester: t("semesterC"),
        books: [],
        grades: [t("proodous") + " (40%)", t("finalExam2") + " (60%)"],
      },
    },
    {
      label: t("courseTitle8"),
      path: 2,
      content: {
        hours: 4,
        ects: 15,
        text: "",
        semester: t("semesterC"),
        books: [],
        grades: [],
      },
    },
  ];

  return (
    <div>
      <Header color="orange" title={t("courses")} />
      <div className="container pt-5">
        <Row>
          <h2 className="ta-c blue">{t("wayOfTeaching")}</h2>
          <Tabs
            defaultActiveKey="profile"
            id="justify-tab-example"
            className="mb-3 pt-5"
            justify
          >
            <Tab eventKey="home" title={t("teaching")}>
              <Container>
                <Row>
                  <h3 className="pt-3">{t("requiredLangSkills")}</h3>
                  <div className="container pt-3">{t("tab1Text")}</div>
                  <div className="container pt-3">{t("tab1Text2")}</div>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="profile" title={t("ects")}>
              <Container>
                <Row>
                  <h3 className="pt-3">{t("tab2Title")}</h3>
                  <div className="container pt-3">{t("tab2Text")}</div>
                  <div className="container pt-3">{t("tab2Text2")}</div>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="longer-tab" title={t("eksamina")}>
              <Container>
                <Row>
                  <Col xl={4} md={6}>
                    <img className="img-fluid pt-4" src={IconData} alt="icon" />
                  </Col>
                  <Col xl={8} md={6}>
                    <h3 className="pt-3">{t("eksamina")}</h3>
                    <div className="container pt-3">{t("tab3Text")}</div>
                    <div className="container pt-3">{t("tab3Text2")}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6">
                    <h3 className="pt-3">{t("firstClinicalCourse")}</h3>
                    <div className="container pt-3">
                      {t("firstClinicalCourseText")}
                    </div>
                  </Col>
                  <Col xl="6">
                    <h3 className="pt-3">{t("secondeClinicalCourse")}</h3>
                    <div className="container pt-3">
                      {t("secondeClinicalCourseText")}
                    </div>
                  </Col>
                </Row>
              </Container>
            </Tab>
          </Tabs>
        </Row>
        <hr className="pt-5" />
        <Row>
          <h2 className="ta-c blue pt-5">{t("classCourses")}</h2>
          <Container>
            <Row>
              <Col>
                <h3>
                  {t("look")}
                  <a
                    href="/info/Πρόγραμμα-1ου-εξαμήνου-MSc-ΔΑΕ.docx"
                    variant="outlined"
                    className="orange"
                    target="_blank"
                    rel="noreferrer"
                    download="Πρόγραμμα-1ου-εξαμήνου-MSc-ΔΑΕ.docx"
                  >
                    {t("here")}
                  </a>
                  {t("remainingText")}
                </h3>
              </Col>
            </Row>
          </Container>
          <div
            className="ta-c pt-5 pb-5"
            style={{ maxHeight: "160vw", maxWidth: "100vw" }}
          >
            <img
              className="desktop-course-img pt-5"
              src={imageCourses}
              alt="courses"
              style={{ height: "100%", width: "100%" }}
            />
            <img
              className="mobile-course-img pt-5"
              src={imageMobileStudies}
              alt="courses"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </Row>
        <Row>
          <Accordion t={t} panels={panels} />
        </Row>
      </div>
    </div>
  );
};

export default Course;
