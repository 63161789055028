//Main routes
import Home from "../views/Home";
import Faculty from "../views/Faculty";
import Equipment from "../views/Equipment";
import Faq from "../views/FAQ";
import Course from "../views/Course";
import General from "../views/General";
import Carriers from "../views/Carriers";
import Application from "../views/Application";
import Contact from "../views/Contact";

let indexRoutes = [
  { path: `/home`, name: "Home", component: Home },
  { path: `/faq`, name: "Faq", component: Faq },
  { path: `/equipment`, name: "Equipment", component: Equipment },
  { path: `/faculty`, name: "Faculty", component: Faculty },
  { path: `/courses`, name: "Courses", component: Course },
  { path: `/application-form`, name: "Application", component: Application },
  { path: `/carriers`, name: "Application", component: Carriers },
  { path: "/general-info", name: "General Info", component: General },
  { path: "/contact", name: "Contact Info", component: Contact },
  { redirect: true, from: "/", to: `/home`, component: Home },
];

export default indexRoutes;
