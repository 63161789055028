import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import General from "../assets/img/General.svg";
import Studies from "../assets/img/studiesProgram.svg";
import ThirdIcon from "../assets/img/ThirdIcon.svg";
import FourthIcon from "../assets/img/FourthIcon.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GridButtons = () => {
  const { t } = useTranslation("common");
  return (
    <Container className="py-5">
      <Row>
        <Col xl={6} className="colItems">
          <Link to="/general-info" style={{ textDecoration: "none" }}>
            <div className="colItemsDiv">
              <div className="card-orange" style={{ borderRadius: "8px" }}>
                <Container className="text">
                  <Row className="text-center">
                    <div>
                      <img
                        className="center-block"
                        src={General}
                        alt="General Info"
                        style={{ width: "130px" }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <p className="ta-c pt-3">{t("general")}</p>
                  </Row>
                </Container>
              </div>
            </div>
          </Link>
        </Col>
        <Col xl={6} className="colItems">
          <Link to="/courses" style={{ textDecoration: "none" }}>
            <div className="colItemsDiv">
              <div className="card-blue" style={{ borderRadius: "8px" }}>
                <Container className="text">
                  <Row className="text-center">
                    <div>
                      <img
                        className="center-block"
                        src={Studies}
                        alt="General Info"
                        style={{ width: "192px" }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <p className="ta-c pt-3">{t("courses")}</p>
                  </Row>
                </Container>
              </div>
            </div>
          </Link>
        </Col>
        <Col xl={6} className="colItems">
          <Link to="/carriers" style={{ textDecoration: "none" }}>
            <div className="colItemsDiv">
              <div className="card-blue" style={{ borderRadius: "8px" }}>
                <Container className="text">
                  <Row className="text-center">
                    <div>
                      <img
                        className="center-block"
                        src={ThirdIcon}
                        alt="General Info"
                        style={{ width: "189px" }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <p className="ta-c pt-3">{t("carriers")}</p>
                  </Row>
                </Container>
              </div>
            </div>
          </Link>
        </Col>
        <Col xl={6} className="colItems">
          <Link to="/application-form" style={{ textDecoration: "none" }}>
            <div className="colItemsDiv">
              <div className="card-orange" style={{ borderRadius: "8px" }}>
                <Container className="text">
                  <Row className="text-center">
                    <div>
                      <img
                        className="center-block"
                        src={FourthIcon}
                        alt="General Info"
                        style={{ width: "130px" }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <p className="ta-c pt-3">{t("applicationForm")}</p>
                  </Row>
                </Container>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default GridButtons;
