import React, { useEffect } from "react";
import Header from "../../components/Header";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Carriers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation("common");

  return (
    <>
      <Header color="orange" title={t("carriersTitleOne")} />
      <Container className="pt-5" fluid>
        <Row>
          <Col xl={8} className="custom-div-blue">
            <div
              className="pt-4 pb-4 ta-c"
              style={{ fontSize: "22px", color: "white" }}
            >
              {t("carriersSubTitleOne")}
            </div>
          </Col>
          <Col xl={4}></Col>
        </Row>
      </Container>
      <Container className="pt-5">
        <Row>
          <Col xl={6} className="extra-padding-top">
            <h2 className="ta-c">{t("carriersSubTitleTwo")}</h2>
          </Col>
          <Col xl={6} className="pt-5">
            <p className="text-for-carriers">{t("carriersSubTitleText")}</p>
          </Col>
        </Row>
      </Container>
      <Container className="extra-padding-top" fluid>
        <Row>
          <Col xl={4}></Col>
          <Col xl={8} className="custom-div-blue-right">
            <div
              className=" pt-4 pb-4 ta-c"
              style={{ fontSize: "22px", color: "white" }}
            >
              {t("carriersSubTitleThree")}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="pt-5 pb-5">
        <Row>
          <Col xl={6} className="pt-5">
            <h2 className="ta-c pt-3">{t("carriersSubTitleFour")}</h2>
          </Col>
          <Col xl={6} className="pt-5">
            <p className="text-for-carriers">{t("carriersSubTitleText2")}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Carriers;
