import Image1 from "../../assets/img/equipment/1.jpg";
import Image2 from "../../assets/img/equipment/2.jpg";
import Image3 from "../../assets/img/equipment/3.jpg";
import Image4 from "../../assets/img/equipment/4.jpg";
import Image5 from "../../assets/img/equipment/5.jpg";
import Image6 from "../../assets/img/equipment/6.jpg";
import Image7 from "../../assets/img/equipment/7.jpg";
import Image8 from "../../assets/img/equipment/8.jpg";
import Image9 from "../../assets/img/equipment/9.jpg";
import Image10 from "../../assets/img/equipment/10.jpg";
import Image11 from "../../assets/img/equipment/11.jpg";
import Image12 from "../../assets/img/equipment/12.jpg";
import ImageErg1 from "../../assets/img/equipment/erg1.jpg";
import ImageErg2 from "../../assets/img/equipment/erg2.jpg";
import ImageErg3 from "../../assets/img/equipment/erg3.jpg";
import ImageErg4 from "../../assets/img/equipment/erg4.jpg";
import ImageErg5 from "../../assets/img/equipment/erg5.jpg";

const photos = [
  {
    src: Image7,
    width: 4,
    height: 3,
  },
  {
    src: Image2,
    width: 4,
    height: 3,
  },
  {
    src: Image9,
    width: 4,
    height: 3,
  },
  {
    src: ImageErg3,
    width: 4,
    height: 3,
  },
  {
    src: ImageErg4,
    width: 4,
    height: 3,
  },
];

export default photos;
