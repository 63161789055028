import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import FacultyDetails from "../../components/FacultyDetails";
import { useTranslation } from "react-i18next";
import NikolaosElef from "../../assets/img/faculty/c38700ba-aa1e-47e9-b632-05d8942795461.jpg";
import Mosxopoulos from "../../assets/img/faculty/Μοσχόπουλος-photo.jpg";
import Sidiras from "../../assets/img/faculty/Sidiras.png";
import Doris from "../../assets/img/faculty/IMG_0557.jpg";
import Gail from "../../assets/img/faculty/Gail.jpg";
import Helen from "../../assets/img/faculty/Helen_Grech.png";
import Purdy from "../../assets/img/faculty/biggest.png";
import Musiek from "../../assets/img/faculty/Musiek.jpg";
import Wayne from "../../assets/img/faculty/WayneWilson.jpg";
import Hung from "../../assets/img/faculty/Hung.png";

function Faculty() {
  const { t } = useTranslation("common");

  const dataFaculty = {
    data: {
      facultyEpitropi: [
        {
          category: "Επιτροπή",
          name: t("vasiliki"),
          image:
            "https://www.med.auth.gr/sites/default/files/styles/member_thumb/public/pictures/picture-917-1600196549.jpg?itok=B3loApvE",
          title: t("vasilikiTitle"),
          modal: false,
          info: "https://www.med.auth.gr/users/viliad",
        },
        {
          category: "Επιτροπή",
          name: t("nimatoudis"),
          image:
            "https://www.med.auth.gr/sites/default/files/styles/member_thumb/public/pictures/picture-1397-1587994248.jpg?itok=B6LhdRTg",
          title: t("nimatoudisTitle"),
          modal: false,
          info: "https://www.med.auth.gr/users/jnimatoudis",
        },
        {
          category: "Επιτροπή",
          name: t("grigoriadis"),
          image:
            "https://www.med.auth.gr/sites/default/files/styles/member_thumb/public/pictures/picture-974-1588058724.jpg?itok=InWJ2CCw",
          title: t("grigoriadisTitle"),
          modal: false,
          info: "https://www.med.auth.gr/users/ngrigoriadis",
        },
        {
          category: "Επιτροπή",
          name: t("diakogiannis"),
          image:
            "https://www.med.auth.gr/sites/default/files/styles/member_thumb/public/pictures/picture-1034-1588058069.jpg?itok=Mi2Kt8Qv",
          title: t("diakogiannisTitle"),
          modal: false,
          info: "https://www.med.auth.gr/users/idiakogiannis",
        },
        {
          category: "Επιτροπή",
          name: t("mpozikas"),
          image:
            "https://www.med.auth.gr/sites/default/files/styles/member_thumb/public/pictures/picture-839-1588058028.jpg?itok=j2Zkz2xv",
          title: t("mpozikasTitle"),
          info: "https://www.med.auth.gr/users/mpozikas",
        },
        {
          category: "Βασικοί Διδάσκοντες",
          name: t("vasiliki"),
          image:
            "https://www.med.auth.gr/sites/default/files/styles/member_thumb/public/pictures/picture-917-1600196549.jpg?itok=B3loApvE",
          title: t("vasilikiTitle"),
          modal: false,
          info: "https://www.med.auth.gr/users/viliad",
        },
        {
          category: "Βασικοί Διδάσκοντες",
          name: t("eleftheriadis"),
          image: NikolaosElef,
          title: t("eleftheriadisTitle"),
          modal: true,
          info: {
            name: t("eleftheriadis"),
            info: t("eleftheriadisText"),
            personalLinks: [
              "https://www.paido-orl.gr/",
              "http://www.tinnitus.gr/",
            ],
          },
        },
        // {
        //   category: "Βασικοί Διδάσκοντες",
        //   name: t("sidiras"),
        //   image: Sidiras,
        //   title: t("sidirasTitle"),
        //   modal: true,
        //   info: {
        //     name: t("sidiras"),
        //     info: t("sidirasText"),
        //   },
        // },
        {
          category: "Βασικοί Διδάσκοντες",
          name: t("mosxopoulos"),
          image: Mosxopoulos,
          title: t("mosxopoulosTitle"),
          modal: true,
          info: {
            name: t("mosxopoulos"),
            info: t("mosxopoulosText"),
          },
        },
        {
          category: "Guest Women",
          name: "Doris-Eva Bamiou",
          image: Doris,
          title: "Neuroaudiology Professor",
          modal: false,
          info: "https://iris.ucl.ac.uk/iris/browse/profile?upi=DEBAM22",
        },
        {
          category: "Guest Women",
          name: "Gail Chermak",
          image: Gail,
          title: "Professor in Speech & Hearing Sciences",
          modal: false,
          info: "https://medicine.wsu.edu/administration/gail-d-chermak-ph-d/",
        },
        {
          category: "Guest Women",
          name: "Helen Grech",
          image: Helen,
          title: "Professor in Communication Therapy",
          modal: false,
          info: "https://www.um.edu.mt/profile/helengrech",
        },
        {
          category: "Guest Women",
          name: "Suzanne Carolyn Purdy",
          image: Purdy,
          title: "Professor in Psychology",
          modal: false,
          info: "https://unidirectory.auckland.ac.nz/profile/sc-purdy",
        },
        {
          category: "Guest Men",
          name: "Frank Musiek",
          image: Musiek,
          title: "Professor in Neuroaudiology",
          modal: false,
          info: "https://slhs.arizona.edu/person/frank-musiek-phd-ccc",
        },
        {
          category: "Guest Men",
          name: "Wayne Wilson",
          image: Wayne,
          title: "Associate Professor in Audiology",
          modal: false,
          info: "https://researchers.uq.edu.au/researcher/1178",
        },
        {
          category: "Guest Men",
          name: "Hung THAI VAN",
          image: Hung,
          title: "Professor in Otolaryngology",
          modal: false,
          info: "https://www.chu-lyon.fr/service-audiologie-explorations-otoneurologiques-edouard-herriot",
        },
      ],
    },
  };

  const dataEpitropi = dataFaculty.data.facultyEpitropi.map((d, idx) => {
    return (
      <>{d.category === "Επιτροπή" && <FacultyDetails id={idx} data={d} />}</>
    );
  });

  const basicFaculty = dataFaculty.data.facultyEpitropi.map((d, idx) => {
    return (
      <>
        {d.category === "Βασικοί Διδάσκοντες" && (
          <FacultyDetails t={t} id={idx} data={d} />
        )}
      </>
    );
  });

  const guestWomen = dataFaculty.data.facultyEpitropi.map((d, idx) => {
    return (
      <>
        {d.category === "Guest Women" && (
          <FacultyDetails t={t} id={idx} data={d} />
        )}{" "}
      </>
    );
  });

  const guestMen = dataFaculty.data.facultyEpitropi.map((d, idx) => {
    return (
      <>
        {d.category === "Guest Men" && (
          <FacultyDetails t={t} id={idx} data={d} />
        )}{" "}
      </>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container pt-5">
        <h1>{t("facultyHeader")}</h1>
        <p style={{ color: "gray", fontSize: "18px" }}>
          {t("facultyDescription")}
        </p>
      </div>
      <Container className="pt-2">
        <Container>
          <h3>{t("facultyTitle1")}</h3>
          <hr />
        </Container>
        <Row>{dataEpitropi}</Row>
        <Container className="pt-5">
          <h3>{t("facultyTitle2")}</h3>
          <hr />
        </Container>
        <Row>{basicFaculty}</Row>
        <Container>
          <h3>{t("facultyTitle3")}</h3>
          <hr />
        </Container>
        <Row>{guestWomen}</Row>
        <Container>
          <h3>{t("facultyTitle4")}</h3>
          <hr />
        </Container>
        <Row>{guestMen}</Row>
      </Container>
    </>
  );
}

export default Faculty;
