import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation("common");

  return (
    <>
      <Container className="pt-5">
        <h1>{t("contactTitle")}</h1>
        <p style={{ color: "gray" }}>{t("contactSubtitle")}</p>
      </Container>
      <Container className="pt-2">
        <Row>
          <Col xl={12} md={12} className="pt-5">
            <div className="bg-dark rounded p-5 mt-lg-9">
              <h3 class="white">{t("haveQuestions")}</h3>
              <p class="white ta-c">{t("haveQuestionsText")}</p>
              <address class="mb-0 ta-c">
                <span class="white py-1">{t("24hours")}</span>
                <span class="d-block text-white-70 font-weight-medium py-1">
                  <a href="mailto:msc-apd@med.auth.gr">msc-apd@med.auth.gr</a>
                </span>
                <span class="white py-1">{t("phone")}: 2310999903</span>
                <br />
                <span class="white py-1">{t("days")} </span>
                <span class="white py-1"> {t("hours")} </span>
              </address>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
