import React, { useEffect } from "react";
import Hero from "../../components/Hero";
import Testimonials from "../../components/Testimonials";
import { Container, Modal } from "react-bootstrap";
import WebinarImage from "../../assets/img/EuropeanAPDwebinar.png";
import WebinarImageMobile from "../../assets/img/EuropeanAPDwebinarmobile.png";
import { useTranslation } from "react-i18next";
import "../../assets/css/CustomModal.css";
import experts from "../../jsonData/experts";

function MyVerticallyCenteredModal(props) {
  return (
    <>
      {props.displayPopUp && (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{
            maxWidth: "90%;" /* Adjust as needed */,
            margin: "0 auto",
            overflow: "auto",
            padding: "20px",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-scroll">
            <Container>
              <p
                style={{
                  fontSize: "18px",
                  wordWrap: "break-word",
                  wordBreak: "keep-all",
                  fontWeight: "bold",
                }}
              >
                Ειδικοί που αναλαμβάνουν εξατομικευμένη ακουστική εκπαίδευση σε
                παιδιά και ενήλικες με διάγνωση Διαταραχής Ακουστικής
                Επεξεργασίας (ΔΑΕ). Υπάρχει και η δυνατότητα εξ' αποστάσεως
                ακουστικής εκπαίδευσης.
              </p>
              <hr style={{ borderTop: "3px solid #8c8b8b" }} />
              <div className="custom-modal-content">
                {experts.map((expert, index) => (
                  <div className="custom-modal-item" key={index}>
                    <p>
                      {expert.name}
                      <br />
                      {expert.title}
                      <br />
                      {expert.location}
                      <br />
                      {Array.isArray(expert.phone)
                        ? expert.phone.join(", ")
                        : expert.phone}
                      <br />
                      {expert.email}
                    </p>
                  </div>
                ))}
              </div>
              <hr style={{ borderTop: "3px solid #8c8b8b" }} />
              <div className="custom-modal-content">
                <p
                  style={{
                    fontSize: "18px",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    fontWeight: "bold",
                  }}
                >
                  Ειδικοί που αναλαμβάνουν διάγνωση Διαταραχής Ακουστικής
                  Επεξεργασίας (ΔΑΕ)
                </p>

                <div className="custom-modal-item">
                  <p>
                    Κουτσιμανη Θεανω
                    <br />
                    Ω.Ρ.Λ
                    <br />
                    Κοζάνη
                    <br />
                    6972271107
                    <br />
                    entkoutsimani@gmai.com
                  </p>
                </div>
                <div className="custom-modal-item">
                  <p>
                    Aγγέλη Δήμητρα
                    <br />
                    Ω.Ρ.Λ
                    <br />
                    Αλεξάνδρεια Ημαθία
                    <br />
                    6936422579
                    <br />
                    aggelidim@hotmail.com
                  </p>
                </div>
              </div>
              {/* <Row>
                <Col xl={4} md={6} className="pt-5">
                  <ol
                    style={{
                      color: "gray",
                      fontSize: "18px",
                      paddingBottom: "10px",
                    }}
                  >
                    <li>
                      Αντωνίου Όλγα, Λογοθεραπεύτρια, Σαλαμίνα, Τηλ: 6972082623,
                      Email: antwniou@hotmail.com
                    </li>
                    <li></li>
                  </ol>
                  {/* <img
                  className="desktop-course-img pt-5"
                  src={WebinarImage}
                  alt="courses"
                />
                <img
                  style={{ maxHeight: "600px" }}
                  className="mobile-course-img pt-5"
                  src={WebinarImageMobile}
                  alt="courses"
                /> */}
              {/* </Col>
              </Row> */}
            </Container>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
function Home() {
  // state variable to decide if we should render pop-up
  const [displayPopUp, setDisplayPopUp] = React.useState(false);

  // // when pop-up is closed this function triggers, we pass it to 'onClose' property of the modal
  const closePopUp = () => {
    // setting key "seenPopUp" with value true into localStorage
    sessionStorage.setItem("seenPopUp", true);
    // setting state to false to not display pop-up
    setDisplayPopUp(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation("common");

  // // the useEffect to trigger on first render and check if in the localStorage we already have data about user seen and closed the pop-up
  // useEffect(() => {
  //   // getting value of "seenPopUp" key from localStorage
  //   let returningUser = sessionStorage.getItem("seenPopUp");
  //   // if it's not there, for a new user, it will be null
  //   // if it's there it will be boolean true
  //   // setting the opposite to state, false for returning user, true for a new user
  //   setDisplayPopUp(!returningUser);
  // }, []);

  return (
    <>
      <div className="news" onClick={() => setDisplayPopUp(true)}>
        {t("popUpTitle")}
      </div>
      <Hero />
      <Testimonials />
      <MyVerticallyCenteredModal
        displayPopUp={displayPopUp}
        show={displayPopUp}
        onHide={closePopUp}
        title={t("popUpTitle")}
      />
    </>
  );
}

export default Home;
