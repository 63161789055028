import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

const Panel = (props) => {
  const { label, content, activeTab, index, activateTab, t } = props;
  const ects = content.ects;
  const hours = content.hours;
  const text = content.text;
  const sememster = content.semester;
  const books = content.books;
  const grades = content.grades;
  const [height, setHeight] = useState(0);
  const isActive = activeTab === index;
  const innerStyle = {
    height: `${isActive ? height : 0}px`,
  };
  setTimeout(() => {
    const height = document.querySelector(".panel__inner").scrollHeight;
    setHeight(height);
  }, 333);

  return (
    <>
      <div className="panel" role="tabpanel" aria-expanded={isActive}>
        <button className="panel__label" role="tab" onClick={activateTab}>
          {label}
        </button>
        <div
          className="panel__inner"
          style={innerStyle}
          aria-hidden={!isActive}
        >
          <p className="panel__content">
            <Container>
              <div className="course_block">
                <div className="course_title_section  justify-content-center">
                  <div className="text-lg-right text-secondary">
                    <small className="d-block mb-2 mb-md-1">
                      {t("mandatory")}
                    </small>
                    <span>
                      {sememster}
                      <br />
                      {t("courseHours")}
                      {hours} <br /> {t("ects")}: {ects}
                    </span>
                  </div>
                </div>
              </div>
              {text !== "" ? <hr /> : null}
              <Row>
                {text !== "" ? (
                  <>
                    <Col xl={8}>
                      <h6>{t("courseDetails")}</h6>
                      <p>{text}</p>
                    </Col>
                    <Col xl={4}>
                      <h6>{t("grading")}</h6>
                      <p>{t("extraTextForGrades")}</p>
                      <ul>
                        {grades &&
                          grades.map((d, idx) => {
                            return <li key={idx}>{d}</li>;
                          })}
                      </ul>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row>
                <Col>
                  {books.length > 0 ? (
                    <>
                      <hr />
                      <h6>{t("recommendBooks")}</h6>
                    </>
                  ) : null}
                  {books &&
                    books.map((b, idx) => {
                      return <p>{b}</p>;
                    })}
                </Col>
              </Row>
            </Container>
          </p>
        </div>
      </div>
    </>
  );
};

const Accordion = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTab2, setActiveTab2] = useState(0);
  const { panels, t } = props;

  let filteredData1 = panels.filter((t) => t.path === 1);
  let filteredData2 = panels.filter((t) => t.path === 2);
  console.log(filteredData1);

  return (
    <div className="accordion pt-5" role="tablist">
      <h3>{t("specialization1")}</h3>
      {filteredData1 &&
        filteredData1.map((panel, index) => (
          <Panel
            t={t}
            key={index}
            activeTab={activeTab}
            index={index}
            {...panel}
            activateTab={() =>
              setActiveTab((prev) => (prev.activateTab === index ? -1 : index))
            }
          />
        ))}
      <h3>{t("specialization2")}</h3>
      {panels &&
        filteredData2.map((panel, index) => (
          <Panel
            t={t}
            key={index}
            activeTab={activeTab2}
            index={index}
            {...panel}
            activateTab={() =>
              setActiveTab2((prev) => (prev.activeTab2 === index ? -1 : index))
            }
          />
        ))}
    </div>
  );
};

export default Accordion;
