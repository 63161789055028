import React from "react";
import { Row } from "react-bootstrap";

const Header = (props) => {
  const { title, color } = props;
  return (
    <div className="container-fluid pt-5 pb-5">
      <Row>
        {color === "blue" ? (
          <h1 className="ta-c blue">{title}</h1>
        ) : (
          <h1 className="ta-c orange">{title}</h1>
        )}
      </Row>
    </div>
  );
};

export default Header;
