import React, { useEffect } from "react";
import Header from "../../components/Header";
import { Row, Col } from "react-bootstrap";
import tree from "../../assets/img/tree.svg";
import { useTranslation } from "react-i18next";

const General = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation("common");

  return (
    <>
      <Header color="orange" title={t("general")} />
      <div className="container-fluid pt-5 container-blue pb-5">
        <Row className=" pt-4">
          <h2 className="ta-c blue">{t("general-info-title")}</h2>
        </Row>
        <div className="container pt-5">{t("genericInfoFirstSentence")}</div>
        <div className="container pt-4">{t("genericInfoSecondSentence")}</div>
        <div className="container pt-4">
          {t("genericInfoPart1")}
          <a
            href="https://www.youtube.com/@APDmaster/about"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            APD Master
          </a>{" "}
          {t("genericInfoPart2")}
        </div>
      </div>
      <div className="container pt-5">
        <Row>
          <Col xl={6}>
            <h2 className="blue pt-4 pb-2">{t("aimOfProgramTitle")}</h2>
          </Col>
          <Col xl={6}>
            <p style={{ textAlign: "left" }}>{t("aimOfProgramText")}</p>
          </Col>
        </Row>
        <Row>
          <div className="ta-c pt-5 pb-5">
            <img alt="tree" src={tree} />
          </div>
        </Row>
      </div>
      <div className="container-fluid pt-5 container-blue pb-5">
        <Row className="pt-4">
          <h2 className="ta-c blue">{t("twoPathsTitle")}</h2>
        </Row>

        <h4 className="ta-c pt-4">{t("twoPathSubTitleOne")}</h4>
        <div className="container pt-4">{t("twoPathsTextForTitleOne")}</div>
        <h4 className="ta-c pt-4">{t("twoPathSubTitleTwo")}</h4>
        <div className="container pt-4">{t("twoPathsTextForSecondTitle")}</div>
      </div>
      <div className="border-app">
        <div
          className="app"
          style={{
            borderInlineStart: "4px solid",
            borderBlockStart: "4px solid",
            borderBlockEnd: "4px solid",
            borderInlineEnd: "4px solid",
            borderImageSource: `radial-gradient(circle at top left ,rgb(28, 107, 225),transparent 50%)`,
            borderImageSlice: 1,
            padding: "10px",
          }}
        >
          <nav id="NAV">
            <p style={{ fontSize: "17px" }}>{t("duration")}</p>
            <p style={{ fontSize: "17px" }}>{t("price")}</p>
            <p> </p>
          </nav>
          <main id="MAIN">
            <h1>
              <span>{t("msc")}</span>
              {t("mscBodyPartOne")}
              <br />
              {t("mscBodyPartTwo")}
            </h1>
            <p>{t("mscText")}</p>
            <b>
              <p style={{ fontSize: "20px", fontWeight: "bolder" }}>
                {t("newCicle")}
                <br />
                {t("newCicleText")}{" "}
              </p>
            </b>
          </main>
        </div>
      </div>
    </>
  );
};

export default General;
