import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";

function Faq() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation("common");

  return (
    <>
      <Container className="pt-5">
        <h1>{t("faq")}</h1>
        <p style={{ color: "gray" }}>
          {t("faqSubTitle1")}
          <br />
          {t("faqSubTitle2")}
        </p>
      </Container>
      <Container className="pt-2">
        <Row>
          <Col xl={12} className="pt-5">
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="6">
                <Accordion.Header>{t("q1")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q1Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="7">
                <Accordion.Header>{t("q2")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q2Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("q3")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q3Text1")}
                  <br />
                  {t("q3Text2")}
                  <br />
                  {t("q3Text3")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="1">
                <Accordion.Header>{t("q4")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q4Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="2">
                <Accordion.Header>{t("q5")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q5Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="3">
                <Accordion.Header>{t("q6")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q6Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="4">
                <Accordion.Header>{t("q7")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q7Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="5">
                <Accordion.Header>{t("q8")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q8Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="8">
                <Accordion.Header>{t("q9")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q9Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="8">
                <Accordion.Header>{t("q10")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q10Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="faq-accordion">
              <Accordion.Item eventKey="8">
                <Accordion.Header>{t("q11")}</Accordion.Header>
                <Accordion.Body className="accordion-body-color">
                  {t("q11Text")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Faq;
