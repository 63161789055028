import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import GridButtons from "./GridButtons";
import HeroImage from "../assets/img/APD-WEB.jpg";
import HeroImageMobile from "../assets/img/APD-WEB-mobile.jpg";
//import IndividualIntervalsExample from "../components/Carousel";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Hero = (props) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Container className="container-without-padding" fluid>
        <div className="image-background">
          <img
            className="img-fluid hero-desktop"
            style={{ width: "100%", height: "800px" }}
            src={HeroImage}
            alt="Hero"
          />
          <img
            className="mobile-hero"
            src={HeroImageMobile}
            alt="Hero Mobile"
          />
        </div>
        <div className="hero px-4 py-4 my-5 text-left">
          <h1 class="display-5 fw-bold" style={{ color: "white" }}>
            {t("startDate")}
            <br /> {t("october")}
          </h1>
          <div className="py-3">
            <Link to="/application-form">
              <Button variant="primary">{t("participate")}</Button>
            </Link>
          </div>
        </div>
      </Container>
      {/* <Container className="container-without-padding pt-3 pb-5" fluid>
        <IndividualIntervalsExample />
      </Container> */}
      <Container>
        <Row>
          <Col>
            <h3>{t("theOneAndOnly")}</h3>
          </Col>
        </Row>
      </Container>

      <GridButtons />
    </>
  );
};

export default Hero;
